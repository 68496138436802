<template>
  <div>
    <!-- 角色管理 -->
    <div class="queryBox mb20">
      机器人配置
    </div>

    <!-- 使用双列布局来优化表单展示 -->
    <div style="width: 100%;">
      <el-form size="mini" :label-position="labelPosition" label-width="150px">
        <div style="display: flex; justify-content: space-between;">
          <!-- 左侧表单列 -->
          <div style="width: 48%;">
            <el-form-item label="机器人账号">
              <el-input v-model="form.bot_username" placeholder="请输入机器人账号" style="width: 250px;"></el-input>
            </el-form-item>
            <el-form-item label="机器人token">
              <el-input v-model="form.token" placeholder="请输入机器人token" style="width: 250px;"></el-input>
            </el-form-item>
            <el-form-item label="机器人secret_token">
              <el-input v-model="form.secret_token" placeholder="请输入机器人secret_token" style="width: 250px;"></el-input>
              <div class="cdanger">自定义即可，用于消息来源验证</div>
            </el-form-item>
            <el-form-item label="机器人管理员">
              <el-input type="textarea" :rows="3" v-model="form.manager_account" placeholder="请输入机器人管理员" style="width: 250px;"></el-input>
              <div class="cdanger">多个账号用逗号（,）分隔</div>
            </el-form-item>
          </div>
          
          <!-- 右侧表单列 -->
          <div style="width: 48%;">
            <el-form-item label="平台名称">
              <el-input v-model="form.sys_name" placeholder="请输入系统名称" style="width: 250px;"></el-input>
              <div class="cdanger">机器人消息所带的平台名称</div>
            </el-form-item>
            <el-form-item label="U地址">
              <el-input v-model="form.usdt_addr" placeholder="请输入U地址" style="width: 250px;"></el-input>
            </el-form-item>
            <!-- <el-form-item label="运营群id">
              <el-input :disabled="true" v-model="form.chat_id" placeholder="未绑定" style="width: 250px;"></el-input>
            </el-form-item> -->
            <el-form-item label="运营群名称">
              <el-input :disabled="true" v-model="form.chat_name" placeholder="未绑定" style="width: 250px;"></el-input>
            </el-form-item>
            <el-form-item label="代付单超时通知群">
              <el-input :disabled="true" v-model="form.order_expr_chat_name" placeholder="未绑定" style="width: 250px;"></el-input>
            </el-form-item>
            <el-form-item label="代付单超时通知时间(秒)">
			  <el-input v-model="form.notify_adm_time" placeholder="代付单超时通知时间(秒)" style="width: 250px;"></el-input>
            </el-form-item>
            <el-form-item label="代付单通道超时驳回(秒)">
			  <el-input v-model="form.notify_payway_time" placeholder="代付单通道超时驳回(秒)" style="width: 250px;"></el-input>
            </el-form-item>
            <el-form-item label="是否启用">
              <el-switch
                v-model="form.state"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :width="30"
                active-text="启用"
                inactive-text="停用">
              </el-switch>
            </el-form-item>
          </div>
        </div>
        
        <!-- 额外的输入项 -->
        <div>
          <el-form-item label="安全码">
            <el-input placeholder="请输入安全码" v-model="setKey" style="width: 250px;"></el-input>
          </el-form-item>
          <el-form-item label="谷歌验证码">
            <el-input v-model="googleCode" placeholder="请输入谷歌验证码" style="width: 250px;"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="setTelegram">保存</el-button>
          </el-form-item>
        </div>
      </el-form>
    </div>
    
    <div class="flexX mt20 pageBox">
      <!-- 其他内容 -->
    </div>
    
  </div>
</template>



<script>
	import {
		isEmpty,
		isPositiveNumber,
		isMoney,
		isIncludeHanzi
	} from '@/util/validate'
	import {
		Message
	} from 'element-ui'
	export default {
		data() {
			return {
				loading: false,
				form:{
					bot_username: '',
					token: '',
					secret_token: '',
					manager_account: '',
					sys_name: '',
					usdt_addr: '',
					state: '',
					chat_id: '',
					chat_name: '',
					notify_adm_time: '',
					notify_payway_time: '',
				},
				//新增
				labelPosition: 'right',
				// 安全码
				setKey: '',
				// 谷歌验证码
				googleCode: '',
				
			}
		},
		created() {
			this.getTelegram()
			this.$nextTick(() => {
				let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
				let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
				let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
				let documentHeight = document.documentElement.clientHeight
				this.height = documentHeight - topNav - 20 - pageBox - 40 - 60
				console.log(this.height)
			})
		},
		filters: {
			tofixed: function(value) {
				if (value || value == 0) {
					// return ((value * 1).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
					let val =Math.floor(((value * 1)*100).toPrecision(12))
					return ((val/100).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			}
		},
		methods: {
			
			//角色列表
			getTelegram(){
				this.loading = true
				this.$api.getTelegram({
					page: this.page,
					size: this.pageSize
				}).then(res => {
					if (res.status === 1) {
						
						this.form.bot_username = res.data.username						
						this.form.token = res.data.bot_token						
						this.form.secret_token = res.data.secret_token						
						this.form.manager_account = res.data.manager_account
						this.form.sys_name = res.data.sys_name
						this.form.usdt_addr = res.data.usdt_addr
						this.form.chat_id = res.data.chat_id
						this.form.chat_name = res.data.chat_name
						this.form.notify_adm_time = res.data.notify_adm_time
						this.form.notify_payway_time = res.data.notify_payway_time
						this.form.order_expr_chat_name = res.data.order_expr_chat_name
						this.form.state = res.data.state==1?true:false								
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.loading = false
				})
			},
			//设置权限
			setTelegram(data){
				this.loading = true
				data = {
					bot_username:this.form.bot_username,
					token:this.form.token,
					secret_token:this.form.secret_token,
					state:this.form.state==true?1:0,
					manager_account:this.form.manager_account,
					sys_name:this.form.sys_name,
					usdt_addr:this.form.usdt_addr,
					notify_adm_time:this.form.notify_adm_time,
					notify_payway_time:this.form.notify_payway_time,
					setKey:this.setKey,
					GoogleCode:this.googleCode,
				}
				this.$api.setTelegram(data).then(res=>{
					if(res.status == 1){
						this.loading = false
						this.getTelegram()
						this.$message({type:"success",message:res.msg})
					} else{						
						this.$message({type:"error",message:res.msg})
					}
				}).catch(error=>{
					this.loading = false
				})
			},
			
		},
		watch: {
			total1(val, old) {
				this.$nextTick(() => {
					let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
					let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
					let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
					let documentHeight = document.documentElement.clientHeight
					this.height = documentHeight - topNav - 20 - pageBox - 40 - 60
					console.log(this.height)
				})
			},
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.formBox {
		width: 80%;
		margin: 0 auto;
	}
	.powerItem{
		display: inline-block;
		width: 33%;
		text-align: left;
		margin-bottom: 20px;
	}
	/* 滚动条宽度 */
	  ::-webkit-scrollbar {
	       width: 0px;
	   }
	   table tbody {
	       display: block;
	       overflow-y: auto;
	       -webkit-overflow-scrolling: touch;
			border-bottom: solid 1px #ddd;
	   }	 
	   table tbody tr:last-child td{
			border-bottom: none;
	   }	 
	   table thead tr, table tbody tr, table tfoot tr {
	       box-sizing: border-box;
	       table-layout: fixed;
	       display: table;
	       width: 100%;
	   }
	   table td{
		   word-break:break-all;
	   }
</style>
